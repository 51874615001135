import { main } from '@popperjs/core';
import '../sass/project.scss';

/* Project specific Javascript goes here. */

/*————————————————————————————————————————————————————*\
    ●❱ ANIMATIONS
\*————————————————————————————————————————————————————*/

function slideToggle(el,duration,callback){if(el.clientHeight===0){_s(el,duration,callback,true);}else{_s(el,duration,callback);}}
function slideUp(el,duration,callback){_s(el,duration,callback);}
function slideDown(el,duration,callback){_s(el,duration,callback,true);}
const toggleNavBar=(a,s,c,e)=>{let o=!1;a.forEach(a=>{a.addEventListener("click",()=>{"add"===s?o?(e.forEach(a=>{a.classList.remove(c)}),o=!1):(e.forEach(a=>{a.classList.add(c)}),o=!0):e.forEach(a=>{a.classList.remove(c)})})})};
function _s(el,duration,callback,isDown){if(typeof duration==='undefined')duration=400;if(typeof isDown==='undefined')isDown=false;el.style.overflow="hidden";if(isDown)el.style.display="block";var elStyles=window.getComputedStyle(el);var elHeight=parseFloat(elStyles.getPropertyValue('height'));var elPaddingTop=parseFloat(elStyles.getPropertyValue('padding-top'));var elPaddingBottom=parseFloat(elStyles.getPropertyValue('padding-bottom'));var elMarginTop=parseFloat(elStyles.getPropertyValue('margin-top'));var elMarginBottom=parseFloat(elStyles.getPropertyValue('margin-bottom'));var stepHeight=elHeight/duration;var stepPaddingTop=elPaddingTop/duration;var stepPaddingBottom=elPaddingBottom/duration;var stepMarginTop=elMarginTop/duration;var stepMarginBottom=elMarginBottom/duration;var start;function step(timestamp){if(start===undefined)start=timestamp;var elapsed=timestamp-start;if(isDown){el.style.height=(stepHeight*elapsed)+"px";el.style.paddingTop=(stepPaddingTop*elapsed)+"px";el.style.paddingBottom=(stepPaddingBottom*elapsed)+"px";el.style.marginTop=(stepMarginTop*elapsed)+"px";el.style.marginBottom=(stepMarginBottom*elapsed)+"px";}else{el.style.height=elHeight-(stepHeight*elapsed)+"px";el.style.paddingTop=elPaddingTop-(stepPaddingTop*elapsed)+"px";el.style.paddingBottom=elPaddingBottom-(stepPaddingBottom*elapsed)+"px";el.style.marginTop=elMarginTop-(stepMarginTop*elapsed)+"px";el.style.marginBottom=elMarginBottom-(stepMarginBottom*elapsed)+"px";}
if(elapsed>=duration){el.style.height="";el.style.paddingTop="";el.style.paddingBottom="";el.style.marginTop="";el.style.marginBottom="";el.style.overflow="";if(!isDown)el.style.display="none";if(typeof callback==='function')callback();}else{window.requestAnimationFrame(step);}}
window.requestAnimationFrame(step);}

/*————————————————————————————————————————————————————*\
    ●❱ Navigation accesibility
\*————————————————————————————————————————————————————*/

/*
——— Handles toggling the navigation menu for small screens and enables TAB key
    navigation support for dropdown menus
*/

( function() {
    const siteNavigation = document.getElementById( 'site-navigation' );

    // Return early if the navigation don't exist.
    if ( ! siteNavigation ) {
        return;
    }

    const menu = siteNavigation.getElementsByTagName( 'ul' )[ 0 ];

    if ( ! menu.classList.contains( 'nav-menu' ) ) {
        menu.classList.add( 'nav-menu' );
    }

    // Get all the link elements within the menu.
    const links = menu.getElementsByTagName( 'a' );

    // Get all the link elements with children within the menu.
    // const linksWithChildren = menu.querySelectorAll( '.menu-item-has-children > .ancestor-wrapper > a, .page_item_has_children > .ancestor-wrapper > a' );

    // Toggle focus each time a menu link is focused or blurred.
    for ( const link of links ) {
        link.addEventListener( 'focus', toggleFocus, true );
        link.addEventListener( 'blur', toggleFocus, true );
    }

    /**
     * Sets or removes .focus class on an element.
     */
    function toggleFocus() {
        if ( event.type === 'focus' || event.type === 'blur' ) {
            let self = this;
            // Move up through the ancestors of the current link until we hit .nav-menu.
            while ( ! self.classList.contains( 'nav-menu' ) ) {
                // On li elements toggle the class .focus.
                if ( 'li' === self.tagName.toLowerCase() ) {
                    self.classList.toggle( 'focus' );
                }
                self = self.parentNode;
            }
        }
    }
}() );

/*————————————————————————————————————————————————————*\
    ●❱ MENU MOBILE
\*————————————————————————————————————————————————————*/

let buttons_menu = document.querySelectorAll(".menu-toggle--open"); 
let button_close = document.querySelector("#site-nav-btn-close");
let main_nav = document.getElementById("site-navigation");
let main_backdrop = document.getElementById("menu-brackdrop");


if (buttons_menu.length > 0) {

    buttons_menu.forEach(function(el){
        el.addEventListener("click", function () {
            main_nav.classList.toggle("show--fade");

            toggle_attr_expand(el);
            toggle_attr_expand(button_close);
            main_backdrop.classList.add('active');
        });
    })

}

if (button_close) {
    button_close.addEventListener("click", function () {
        main_nav.classList.toggle("show--fade");

        // toggle_attr_expand(button_menu);
        toggle_attr_expand(button_close);
        main_backdrop.classList.remove('active');
    });
}

let sub_menu_toggles = document.querySelectorAll(".ancestor-wrapper .sub-menu-toggle");
if (sub_menu_toggles) {
    sub_menu_toggles.forEach(el=>{
        el.addEventListener("click", function () {
            let parentN = this.parentNode;
            slideToggle(parentN.nextElementSibling, 300);
            toggle_attr_expand(el);
        });
    })
}

/*————————————————————————————————————————————————————*\
    ●❱ SEARCH
\*————————————————————————————————————————————————————*/

const searchForms = document.querySelectorAll('#search-form'),
    searchIcons = document.querySelectorAll('#search-icon'),
    closeIcons = document.querySelectorAll('#close-search');

toggleNavBar(searchIcons, 'add', 'search--show', searchForms);
toggleNavBar(closeIcons, 'remove', 'search--show', searchForms);

if (searchIcons.length > 0) {
    searchIcons.forEach(searchIcon => {
        searchIcon.addEventListener('click', function() {
            searchForms.forEach(searchForm => {
                if (searchForm.classList.contains('search--show')) {
                    closeIcons.forEach(closeIcon => closeIcon.classList.remove('hidden'));
                    searchIcon.classList.add('hidden');
                } else {
                    closeIcons.forEach(closeIcon => closeIcon.classList.add('hidden'));
                    searchIcon.classList.remove('hidden');
                }
            });
        });
    });
}

if (closeIcons.length > 0) {
    closeIcons.forEach(closeIcon => {
        closeIcon.addEventListener('click', function() {
            searchForms.forEach(searchForm => {
                if (searchForm.classList.contains('search--show')) {
                    closeIcon.classList.remove('hidden');
                    searchIcons.forEach(searchIcon => searchIcon.classList.add('hidden'));
                } else {
                    closeIcon.classList.add('hidden');
                    searchIcons.forEach(searchIcon => searchIcon.classList.remove('hidden'));
                }
            });
        });
    });
}



function toggle_attr_expand(element){

    if (element.getAttribute("aria-expanded") === "true") {
        element.setAttribute("aria-expanded", "false");
    } else {
        element.setAttribute("aria-expanded", "true");
    }
}
